import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useQueryParam, StringParam } from "use-query-params"

import SEO from "../components/seo"
import FileBrowser from "../components/FileBrowser"
import Breadcrumbs from "../components/Breadcrumbs"
import S3Image from "../components/S3Image"
// import Image from "../components/Image"

// Hooks
import useDebounce from "../hooks/useDebounce"

const blockVariants = {
  // initial: { y: 20, opacity: 0 },
  // enter: { y: 0, opacity: 1, ease: [0.43, 0.13, 0.23, 0.96] },
  // exit: {
  //   y: 20,
  //   opacity: 0,
  //   transition: { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] },
  // },
}

const FilesPage = ({ location, data: { allS3Object }, pageContext }) => {
  const { key } = pageContext
  const [overviewVisible, showOverview] = useState(false)

  const [description, setDescription] = useState("No description. ")

  const [password, setPassword] = useState("")

  const [passwordProtected, setPasswordProtected] = useState(false)

  const debouncedPassword = useDebounce(password, 500)

  let breadcrumbArr = key.split("/")

  let title = breadcrumbArr.slice(-1).pop()

  let allFolderFiles = []

  let folderPath = ""

  

  const listOfAllObjects = allS3Object.nodes.map((node) => ({ Key: node.Key, Size: node.Size}));


  if (key === "/") {
    allFolderFiles = listOfAllObjects.filter(
      file =>
        !file.Key.includes("/") ||
        (file.Key.split("/").length === 2 && file.Size === 0)
    )

    breadcrumbArr = [""]
  } else {
    allFolderFiles = listOfAllObjects.filter(
      file => file.Key.includes(key) && file.Key !== key + "/"
    )

    allFolderFiles = allFolderFiles.filter(
      file =>
        file.Key.replace(/\/$/, "").split("/").length ===
        key.split("/").length + 1
    )

    folderPath = key + "/"
  }

  useEffect(() => {
    const hasFolderDescription = allFolderFiles.find(file =>
      file.Key.includes("folder.txt")
    )

    if (!hasFolderDescription) {
      return
    }

    fetch(`https://6lackbox-prod.s3.amazonaws.com/${folderPath}folder.txt?cb=1`)
      .then(r => r.text())
      .then(text => {
        setDescription(text)
      })
  }, [])

  // Remove folder files
  const filteredFolderFiles = allFolderFiles.filter(
    file => !file.Key.includes("folder.")
  )

  const [shareUri, setShareUri] = useQueryParam("share", StringParam)
  const [previewUri, setPreviewUri] = useQueryParam("preview", StringParam)

  let breadcrumbArrParsed = []
  breadcrumbArr.forEach((item, idx) =>
    breadcrumbArrParsed.push({
      label: item,
      to: `${
        breadcrumbArrParsed && breadcrumbArrParsed[idx - 1]
          ? breadcrumbArrParsed[idx - 1].to
          : ""
      }/${item}`,
    })
  )

  // Links
  useEffect(() => {
    if(typeof window !== "undefined" && folderPath === "Voter Registration/") window.location.href = `https://www.vote.org/register-to-vote/`
    if(typeof window !== "undefined" && folderPath === "6pc scratch & win/") window.location.href = `http://6lack.com/scratchandwin`
    if(typeof window !== "undefined" && folderPath === "Black owned businesses/") window.location.href = `https://officialblackwallstreet.com/directory/`
    if(typeof window !== "undefined" && folderPath === "Coming In Hot/") window.location.href = `https://www.cyanbot.com/6lack`
  }, [folderPath])

  // Password Protected
  useEffect(() => {
    setPasswordProtected(folderPath === "Album 3/" || false);
    if (debouncedPassword.length < 2) return

    if (debouncedPassword) {
      fetch(`/api/getPass?password=${debouncedPassword}`)
        .then(r => r.json())
        .then(data => {
          if(data.msg === "OK"){
            setPasswordProtected(false)
          }
        })
    }
  }, [debouncedPassword, setPasswordProtected, folderPath])

  return (
    <motion.article
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{
        exit: {
          opacity: 0,
          transition: { staggerChildren: 0.1, duration: 0.1 },
        },
        enter: {
          opacity: 1,
          transition: { staggerChildren: 0.1, duration: 0.1 },
        },
        initial: { opacity: 0 },
      }}
      className="relative flex flex-col flex-1 ease-in-out main transition-slowest transition-color"
    >
      {!previewUri && <SEO title={title} />}

      <motion.div className="flex flex-col flex-1 p-6 overflow-hidden sm:overflow-y-scroll md:p-10">
        <motion.header className={`relative mb-4`} variants={blockVariants}>
          {/* <div className="flex items-center text-xl leading-none">
            <Link to="/" className="font-medium">6lackbox</Link>
            <FaChevronRight className="w-3 h-3 mx-2 text-gray-400 " />
            <span className="font-medium text-gray-400">6lackbox</span>
          </div> */}
          <Breadcrumbs
            className="flex items-center text-xl leading-none"
            links={breadcrumbArrParsed}
          />
          <div className="block mt-2 sm:hidden">
            <span className="block text-xs text-theme-white">Only you</span>
            <button
              type="button"
              onClick={() => setShareUri(key, "pushIn")}
              className="inline-flex justify-center px-4 py-1 mt-2 text-sm leading-5 transition duration-150 ease-in-out rounded-md bg-theme-highlight text-theme-darkest focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            >
              Share
            </button>
          </div>
          <button className="hidden ml-auto sm:block">
            {/* <Image
              filename="desktop-wallpaper"
              alt=""
              className="object-cover w-8 h-8 rounded-full"
            /> */}
          </button>
        </motion.header>
        {passwordProtected && (
          <motion.div className="flex mb-10 " variants={blockVariants}>
            <label>
              <span>Password: </span>
              <input
                className="px-2 py-1 text-white bg-black rounded"
                type="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </label>
          </motion.div>
        )}
        {!passwordProtected && (
          <motion.div className="flex flex-1 " variants={blockVariants}>
            <div className="flex flex-col flex-1 w-0 overflow-hidden">
              <div className="flex py-3 mb-5 border-b border-theme-mid">
                <h4 className="text-xs">Overview</h4>
                <button
                  className="ml-auto text-xs text-theme-highlight"
                  onClick={() => showOverview(!overviewVisible)}
                >
                  {overviewVisible ? "Hide" : "Show"}
                </button>
              </div>
              <div
                className={`text-base text-theme-white mb-10 ${
                  overviewVisible ? "block" : "hidden"
                }`}
              >
                <p>{description}</p>
              </div>
              <FileBrowser files={filteredFolderFiles} />
              <div className="hidden pt-10 mt-auto text-xs lg:block ">
                <a
                  href="http://www.eastatlantaloveletter.com/signup"
                  target="_blank"
                  className="mr-5 hover:opacity-75"
                  rel="noopener noreferrer"
                >
                  email sign up
                </a>
                <a
                  href="https://6lackmarket.com/"
                  target="_blank"
                  className="mr-5 hover:opacity-75"
                  rel="noopener noreferrer"
                >
                  shop
                </a>
              </div>
            </div>

            <div className="relative z-10 flex-col flex-grow flex-shrink-0 hidden w-full max-w-xs pt-16 pl-10 lg:flex">
              <div className="relative block overflow-hidden rounded bg-theme-dark">
                <S3Image
                  filename={`${folderPath}folder.jpg`}
                  className="object-fit-cover"
                  fallback
                />
              </div>
              <div className="pb-6 mt-12 border-b border-theme-mid">
                <button
                  type="button"
                  onClick={() => setShareUri(key, "pushIn")}
                  className="inline-flex justify-center w-full px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out bg-transparent border rounded-md border-theme-light text-theme-offwhite hover:text-theme-highlight hover:border-theme-highlight focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                >
                  Share folder
                </button>
                <span className="block mt-6 text-xs">Only you have access</span>
              </div>
            </div>
          </motion.div>
        )}

        <div className="absolute bottom-0 right-0 mb-10 mr-10 text-xs lg:block ">
          <a
            href="http://designedmemory.com"
            target="_blank"
            className="text-gray-600 opacity-75"
            rel="noopener noreferrer"
          >
            designedmemory
          </a>
        </div>
      </motion.div>
    </motion.article>
  )
}

export default FilesPage

export const pageQuery = graphql`
  query allS3Files {
    allS3Object {
      nodes {
        Key
        ETag
        IsTruncated
        KeyCount
        LastModified
        MaxKeys
        Name
        Prefix
        Size
        StorageClass
        Url
        id
      }
    }
  }
`
