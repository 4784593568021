import React from "react"
import { Link } from "gatsby"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

const Breadcrumbs = ({ links }) => {
  const activeLink = links.pop()
  links.unshift({ label: "6lackbox", to: "/" })
  const link = (label, to, className) => (
    <Link to={to} className={`${className}`}>
      {label}
    </Link>
  )
  
  return (
    <nav className="flex items-center text-xl leading-none">
      <ol className="hidden list-reset sm:flex">
        {links.length > 0 &&
          links.map((l, idx) => (
            <li key={idx}>
              {link(l.label, l.to, "font-medium", idx)}
              <span className="inline-block font-medium">
                <FaChevronRight className="w-3 h-3 mx-2 text-gray-400 " />
              </span>
            </li>
          ))}
        <li key="active">
          <span className="font-medium text-gray-400">{activeLink.label}</span>
        </li>
      </ol>
      <ol className="flex items-center list-reset sm:hidden">

      {activeLink.to === "/" && (
        <span className="font-medium text-gray-400">{links[0].label}</span>
      )}

        {links.length > 0 && activeLink.to !== "/" && (
          <>
          <Link
            to={links[links.length - 1].to}
            className={`inline-block font-medium py-1`}
          >
            <FaChevronLeft className="w-3 h-3 mx-2 text-gray-400 " />
          </Link>
          <li key="active">
          <span className="font-medium text-gray-400">{activeLink.label}</span>
        </li>
        </>
        )}

        
      </ol>
    </nav>
  )
}

export default Breadcrumbs
